
import { Component, Vue } from "vue-property-decorator";
import Teaser from "@/components/Shared/Teaser/Teaser.vue";
import ExampleTeaserI from "@/entities/ExampleTeaser";
import BasePage from "@/components/Shared/Page/Page.vue";
import { examples } from "@/entities/data";
import UploadedFile from "@/entities/UploadedFile";

/**
 * All examples
 */
@Component({
  name: "ExamplesPage",
  components: {
    Teaser,
    BasePage,
  },
})
export default class Page extends Vue {
  private groupByTeasers: { [key: string]: ExampleTeaserI[] } = {};

  private created() {
    examples.forEach((example) => {
      if (!this.groupByTeasers[example.type]) {
        this.groupByTeasers[example.type] = [];
      }
      this.groupByTeasers[example.type].push(example);
    });
  }

  private finish(uploadedFile: UploadedFile) {
    /**
     * Emit the uploaded file
     *
     * @type {UploadedFile}
     */
    this.$emit("finish", uploadedFile);
  }
}
